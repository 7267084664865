import React, { useState } from 'react';
import { Tabs } from '@decub8/ui';
import Link from 'next/link';

import { Content, Meta, StakingAnalytics, StakingPools } from '@src/components';
import { CONTENT, USES_LEGACY_STAKING } from '@src/config';
import { useContent } from '@src/hooks/useContent';

const options = [
    { title: 'Staking', id: 'staking' },
    { title: 'Legacy staking', id: 'legacy-staking' },
];

const Staking: React.FC = () => {
    const [nav, setNav] = useState(options[0].id);
    const { stakingBanner } = useContent();

    const is_legacy = nav === options[1].id;

    return (
        <div className={`pt-5`}>
            <Meta title="Staking" />
            <Content>
                {USES_LEGACY_STAKING && (
                    <div className="flex justify-center w-full mt-[12px]">
                        <div className="w-80">
                            <Tabs
                                current={nav}
                                setCurrent={(id) => setNav(id)}
                                options={options}
                            />
                        </div>
                    </div>
                )}
                {stakingBanner && (
                    <div className="rounded-lg mb-5 mt-8 mx-auto">
                        <Link href={stakingBanner?.link} target="_blank">
                            <img
                                src={stakingBanner?.desktop}
                                alt={CONTENT.companyName}
                                className="hidden md:inline w-full rounded"
                            />
                            <img
                                src={stakingBanner?.mobile}
                                alt={CONTENT.companyName}
                                className="md:hidden w-full rounded"
                            />
                        </Link>
                    </div>
                )}

                <StakingAnalytics is_legacy={is_legacy} />
                <StakingPools is_legacy={is_legacy} />
            </Content>
        </div>
    );
};

export default Staking;
